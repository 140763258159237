import React from 'react';
import ProductPage from '../../client-ui/products/ProductPage'
import { getRandomString } from '../../locust/composables/Shared'

export default function Products({ params }) {
    
    //const param = params[`*`]
    const [ url, setUrl ] = React.useState(false)
    
    React.useEffect(()=>{

        const extractURLAfterKey = ( key ) => {
            const url = window.location.href
            const indexUID = url.indexOf(`/${key}`) + `/${key}`.length + "/".length
            const uid = url.substring(indexUID, url.length).trim()
            return uid
        }

        const url = extractURLAfterKey('products')

        setUrl( url )

    },[])

    return (
        <>
            { url && <ProductPage url={url} purchaseId={getRandomString()}/> }
        </>
    )
}