import { css } from 'glamor'
import React from 'react'
import { getFontMontserrat } from '../../../assets/fonts/FontMontserrat'
import { SectionTitle } from '../../../client-ui/checkout/CheckoutSectionTitle'
import { TextInclusionSmall } from '../../../client-ui/checkout/Typography'
import { SecondaryTitleSubtitleII } from '../../../client-ui/shared/SecondaryTitleSubtitle'
import { TwoColumn } from '../../../locust/components/Column'
import Hide from '../../../locust/composables/Hide'
import Inline from '../../../locust/composables/Inline'
import { getConfigDefaults } from '../../../locust/composables/Layout'
import Scale from '../../../locust/composables/Scale'
import { SpacerVRsp } from '../../../locust/composables/SpacerVRsp'
import X from '../../../locust/composables/X'
import XY from '../../../locust/composables/XY'
import Y from '../../../locust/composables/Y'
import SpacerV from '../../../locust/sections/SpacerV'


export interface ProductFeatureBullet {
    bullet : string | React.ReactNode,
    value : string | React.ReactNode,
} 


enum Size {
    IMAGE_WIDTH = 300,
    CONTENT_SCALE = 0.8,
    CONTENT_WIDTH = 400,
    CONTENT_LEFT_PADDING = 20,
}

const FeatureProductLR = ( props : { 
    title : string | React.ReactNode,
    subtitleLine1 : string | React.ReactNode,
    subtitleLine2 : string | React.ReactNode,
    description: string | React.ReactNode,
    bullets ?: ProductFeatureBullet[],
    image ?: React.ReactNode
    right ?: boolean

}) => {
    return (
        <div style={{ backgroundColor : ''}}>
            
                <Hide on={ Size.IMAGE_WIDTH  + Size.CONTENT_WIDTH + Size.CONTENT_LEFT_PADDING }>    
                    <DesktopTablet {...props}/>
                </Hide>
                
                <Hide b4={ Size.IMAGE_WIDTH  + Size.CONTENT_WIDTH + Size.CONTENT_LEFT_PADDING }>    
                    <MobileMin {...props}/>
                </Hide>
            
            
        </div>
    )
}






const DesktopTablet = ( props : any ) => {
    
    const [loaded, setLoaded ] = React.useState(false)
    React.useEffect(()=>{
        setLoaded(true)
    },[])

    const on = ( value : number ) => getConfigDefaults().onMedia( value )
    
    const image = css({
        width : Size.IMAGE_WIDTH,
        height : 580,
    })

    const right : any = (
        <Inline>
            <div
                style={{ 
                    height : 'auto', 
                    width : 300,
                    verticalAlign : 'top',
                    //backgroundColor : 'blue',
            }}
            >   
                
                {props.image}
                
            </div>
        </Inline>
    )

    const left : any = (
            <Inline>
                <div style={{ paddingLeft : Size.CONTENT_LEFT_PADDING }}>
                    <Scale scale={Size.CONTENT_SCALE}>
                        <div
                            style={{
                                width : Size.CONTENT_WIDTH,
                                height : 0, 
                                backgroundColor : 'lime',
                                verticalAlign : 'top'

                            }}
                        >
                        

                        <SecondaryTitleSubtitleII align="left">
                            <>{ props.title }</>
                            <>  
                                <p style={{ display : 'block'}}>{props.subtitleLine1}</p>
                                <p style={{ display : 'block'}}>{props.subtitleLine2}</p>
                            </>
                        </SecondaryTitleSubtitleII>
                        
                    

                        
                            
                        

                        <SpacerV h={10}/>

                        <TextInclusionSmall>
                            {props.description}
                        </TextInclusionSmall>

                        { props.bullets?.map(( item : ProductFeatureBullet )=>{

                            return (
                                <>
                                    <SpacerV h={40}/>
                                    <TwoColumn
                                        styles={{
                                            left : {
                                                width : 70,
                                                height : '100%'
                                            },
                                            right : {
                                                width : 'calc(100% - 70px)',
                                                height : 'auto'
                                            }
                                        }}
                                        left={
                                            <>
                                                <div style={{ backgroundColor : ''}}>
                                                <p style={{ ...getFontMontserrat(), fontSize : 40, fontWeight : 900 }}>{item.bullet}</p>
                                                </div>
                                            </>
                                        }
                                        right={
                                            
                                            <SectionTitle style={{ width : 380, marginTop : 0 }}>
                                                <p style={{ fontSize : 15, fontWeight : 350, letterSpacing : 0.8, textAlign : 'left' }}>
                                                    {item.value}
                                                </p>
                                            </SectionTitle>    
                                        }
                                    />
                                </>
                            )
                        })}
                    </div>
                </Scale>
            </div>
        </Inline>
    )
    
    if( !loaded) return <></>
    
 

    return (
        <>
                <div style={{ marginBottom : -200 }}>
                
                    { props.right ? right : left }
                    { props.right ? left : right }
                </div>
            
        </>
    )
}




const MobileMin = ( props : any ) => {
    
    const [loaded, setLoaded ] = React.useState(false)
    React.useEffect(()=>{
        setLoaded(true)
    },[])

    const on = ( value : number ) => getConfigDefaults().onMedia( value )
    
    const image = css({
        width : Size.IMAGE_WIDTH,
        height : 580,
    })

    const right : any = (
        <>
            <div style={{ marginLeft : 7}}>
                {props.image}
            </div>
        </>
    )

    const left : any = (
        <X>
       <Scale scale={Size.CONTENT_SCALE}>
                
                <div
                    style={{
                        width : Size.CONTENT_WIDTH,
                        height : '100%', 
                        //backgroundColor : 'lime',
                        verticalAlign : 'top',
                        marginLeft : 'calc(50% - 170px)'

                    }}
                >
                
                    <SecondaryTitleSubtitleII align="left">
                        <>{ props.title }</>
                        <>  
                            <p style={{ display : 'block'}}>{props.subtitleLine1}</p>
                            <p style={{ display : 'block'}}>{props.subtitleLine2}</p>
                        </>
                    </SecondaryTitleSubtitleII>

                    <SpacerV h={20}/>

                    <TextInclusionSmall>
                        {props.description}
                    </TextInclusionSmall>

                    
                        <div style={{ marginBottom : -280, backgroundColor : ''}}>
                            <X>
                                <Scale scale={1.2} origin={"top"}>
                                    {right}
                                </Scale>
                            </X>
                        </div>
                    

                    
                    
                </div>
            
        </Scale>
        </X>
        
    )
    
    if( !loaded) return <></>
    
 

    return (
        <>
            
                
                    { left }
                
            
        </>
    )
}

export default FeatureProductLR;