import { Button, TextField } from '@mui/material'
import { motion } from 'framer-motion'
import { css } from 'glamor'
import React from 'react'
import { ItemPrice, TextInclusionSmall } from '../../client-ui/checkout/Typography'
import { SubtitleIIRsp } from '../../locust/components/Auth'
import { OneColumn, TwoColumn, TwoColumnAuto } from '../../locust/components/Column'
import { LineDivider, LineDividerSpacer } from '../../locust/components/LineDivider'
import { RadioBoxListItem } from '../../locust/components/Payment'
import { Popup } from '../../locust/components/PopupSlide'
import StarRating from '../../locust/components/StarRating'
import { getConfigDefaults } from '../../locust/composables/Layout'
import { SpacerVRsp } from '../../locust/composables/SpacerVRsp'
import X from '../../locust/composables/X'
import XY from '../../locust/composables/XY'
import Y from '../../locust/composables/Y'
import SpacerV from '../../locust/sections/SpacerV'
import BoxTitle from '../shared/BoxTitle'
import ProductPopup from './ProductPopup'
import ProductRating from './ProductRating'
import { addToShoppingCart, getShoppingCart } from '../../service/shopping-cart'


const ProductInfo = ( props : { 
    itemParams : any, 
    setPopup ?: ( props : Popup ) => void  
    cardValuesFront ?: any,
    setCardValuesFront ?: ( params : any ) => void,
    cardValuesBack ?: any,
    setCardValuesBack ?: ( params : any ) => void
    cardColors?: any,
    setCardColors ?: ( params : any ) => void,
    cardLogo?: any, 
    setCardLogo?: ( params : any ) => void,
    setShoppingCartLength : ( value : number ) => void,
    purchaseId ?: string,
    setProductAdded ?: ( value : boolean ) => void, 
}) => {

    
    const styles : any = {
        styles : {
            left : {
                marginTop : -4,
                width : 140
            },
            right : {
                marginTop : -4,
                width : 140
            }
        }
    }

    const on = ( value : number ) => getConfigDefaults().onMedia( value )
    
    return (
        <div style={{ marginLeft : 0, maxWidth : 500, width : '100%', backgroundColor : '' }}>  
            
            <SpacerV h={10}/>

            
            <ProductRating 
                container={{ width : 300 }}
                title={props.itemParams.info.title}
                subtitle={props.itemParams.info.subtitle}
                ratings={props.itemParams.info.ratings}
            />
            
            
            <ButtonAndPrice 
                setPopup={props.setPopup}
                cardValuesFront={props.cardValuesFront}
                cardValuesBack={props.cardValuesBack}
                cardColors={props.cardColors}
                cardLogo={props.cardLogo}
                itemParams={props.itemParams}
                purchaseId={props.purchaseId}
                setProductAdded={props.setProductAdded}
                
                //price={props.info.price}
            />

            <SpacerVRsp breakpoint={525} before={0} after={40}/>
            
            <div {...css({
                [on(525)] : {
                    marginLeft : 20,
                    width :'calc(100% - 40px)'
                }
                

            })}>
                <LineDividerSpacer top={70} bottom={20}/>
                
            
                { props.itemParams.info.details.map(( feature : any )=>{
                    return (
                        <TwoColumn
                            container={{ width : 300}}
                            {...styles}
                            left={
                                <BoxTitle style={{ }}>{ feature.id }</BoxTitle>
                            }
                            right={
                                <BoxTitle style={{ }}><p style={{ fontWeight : 300 }}>{ feature.value }</p></BoxTitle>
                            }
                        />
                    )
                })}
                
                
                <LineDividerSpacer top={10} bottom={20}/>
                
                
                <X align="left" style={{ height : 'auto', width : '100%' }}>   
                    <SubtitleIIRsp left style={{ color : 'black', display : 'inline-block'}}>
                        About this item
                    </SubtitleIIRsp>
                </X>

                <Y>
                    <SpacerV h={10}/>
                    <X align="left" style={{ height : 'auto', width : '100%' }}>  
                        <div style={{ marginLeft : -26, width : 600 }}>
                            {props.itemParams.info.about.map(( item : any, index : number )=>{
                                return (
                                    <div>
                                        { index > 0 && <SpacerV h={6}/>}
                                        <RadioBoxListItem fontStyle={{ textAlign : 'left', fontSize : 13 , fontWeight : 300}}>
                                            {item}
                                        </RadioBoxListItem> 
                                    </div>
                                )

                            })}
                        </div> 
                    </X>
                </Y>
        
            </div>

            

        </div>
    )
}

const ButtonAndPrice = ( props : { 
    setPopup ?: ( props : Popup ) => void,  
    //price : number, 
    itemParams : any,
    cardValuesFront : any,
    cardValuesBack : any,
    cardColors : any,
    cardLogo : any 
    container ?: Record<string,any>,
    purchaseId ?: string,
    setProductAdded ?: ( value : boolean ) => void,   

}) => {
    const on = ( value : number ) => getConfigDefaults().onMedia( value )
    const price : number = props.itemParams.info.price
    const type : string = props.itemParams.info.type
    return (
        <div {...css({
            //backgroundColor : 'red',
            width : 200,
            height : 10,
            verticalAlign : 'top',
            display : 'inline-block',
            ...props.container,
            [on(525)] : {
                marginTop : 10,
                width :'calc(100% - 40px)',
                textAlign : 'center'
            }
        })}>
      
            <div {...css({ 
                float : 'right',
                [on(525)] : { 
                    clear : 'both', 
                    width : '100%',
                    textAlign : 'center',
                }
            })}>
                    
                    
                    <ItemPrice 
                        style={{ 
                            marginTop : -10, 
                            backgroundColor : '', 
                            width : '100%', 
                            fontWeight: 400, 
                            fontSize : 20,
                        }}>
                        {price}
                    </ItemPrice>

                    <SpacerVRsp breakpoint={525} before={4} after={20}/>

                    <Button
                        onClick={()=>{

                            if(props.itemParams.info.type === "product"){
                                if( props.setPopup )
                                    props.setPopup({ 
                                        toggle : true, 
                                        title : 'Confirm',
                                        children: <>
                                            <ProductPopup
                                                cardValuesFront={props.cardValuesFront}
                                                cardValuesBack={props.cardValuesBack}                                            
                                                cardColors={props.cardColors}
                                                cardLogo={props.cardLogo}
                                            />
                                        </> 
                                    })  
                            }
                            else if(props.itemParams.info.type === "subscription"){
                                
                                const subscription : any = {
                                    desc : props.itemParams.info.title,
                                    type : 'subscription',
                                    price :props.itemParams.info.price,
                                    name : props.itemParams.info.subtitle,
                                    id : props.itemParams.info.url,
                                    img : props.itemParams.subscription.img,
                                    quantity : 1, 
                                    productId : props.itemParams.subscription.productId,
                                    order : { link : props.purchaseId }
                                }
                                
                                addToShoppingCart( subscription, props.purchaseId )
                                if(props.setProductAdded) props.setProductAdded(true)

                                // if( ENABLE_ERROR_LOGGING ) console.log( props.purchaseId )
                                // if( ENABLE_ERROR_LOGGING ) console.log( props.itemParams )
                                // if( ENABLE_ERROR_LOGGING ) console.log( getShoppingCart() )
                                // if( ENABLE_ERROR_LOGGING ) console.log(subscription)



                            }
                                
                        }}
                        style={{
                            marginTop : -5, 
                            width : 200, 
                            height : 50,
                            backgroundColor : 'black', 
                        }}
                        
                    >
                        <p style={{ color : 'white', fontWeight : 600, fontSize : 18 }}>Add to Cart</p>
                    </Button>

                    


                
            </div>
        
        </div>
    )
}
export default ProductInfo;