import { Button } from "@mui/material"
import { css } from "glamor"
import React from "react"
import { FACEBOOK_BLACK } from "../../assets/colors/Colors"
import { TwoColumnAuto } from "../../locust/components/Column"
import HeaderOnScrollWrapper from "../../locust/components/HeaderOnScroll"
import { LineDivider, LineDividerSpacer } from "../../locust/components/LineDivider"
import { Popup, PopupContainer } from "../../locust/components/PopupSlide"
import StarRating from "../../locust/components/StarRating"
import AuthManager from "../../locust/composables/AuthManager"
import { getConfigDefaults } from "../../locust/composables/Layout"
import { getRandomString } from "../../locust/composables/Shared"
import X from "../../locust/composables/X"
import Y from "../../locust/composables/Y"
import SpacerV from "../../locust/sections/SpacerV"
import { addToShoppingCart, getShoppingCartLength } from "../../service/shopping-cart"
import { ItemPrice } from "../checkout/Typography"
import CobraLayout from "../shared/LayoutCobra"
import { Page, to } from "../shared/SiteLinks"
import ProductAddedPage from "./ProductAddedPage"
import ProductFeatures from "./ProductFeatures"
import ProductLanding from "./ProductLanding"
import ProductPopup from "./ProductPopup"
import ProductRating from "./ProductRating"
import ProductTitleRsp from "./ProductTitleRsp"
import { getProductParamsByURL, stripStyleFromCusomizationObject } from "./Shared"
import { ENABLE_ERROR_LOGGING } from "../../settings/project-settings"





const ProductPage = (props : {
    url : string,
    purchaseId ?: string
}) => {

    const optionsBoxScale : number = 0.9
    const [ cardParams, setCardParams ] = React.useState<any>( getProductParamsByURL( props.url ) )
    const [ popup, setPopup ] = React.useState<Popup>({ toggle : false, title : "" })
    const [ shoppingCartLength, setShoppingCartLength ] = React.useState(getShoppingCartLength())

    const [productAdded, setProductAdded ] = React.useState<any>(false)

    const on = ( value : number ) => { return getConfigDefaults().onMedia( value )}
    
    const productName : string = cardParams.info.subtitle
    const [breadcrumb, setBreadcrumb] = React.useState([
        { value : 'Home', url : to(Page.HOME) },
        { value : 'Products', url : `/products/${productName}` },
        { value : productName, url : `/products/${productName}` }
     ])

   
    if( !cardParams ) return <></>

    

    return (
        <>  
            <Container breadcrumb={breadcrumb}>
                <div {...css({
                    //backgroundColor : 'purple',
                    width : 1040,
                    marginLeft : 'calc(50% - 520px)',
                    textAlign : 'center',
                    height : 'auto',
                    overflow : 'hidden',
                    [on(1040)] : {
                        overflow : 'hidden',
                        marginLeft : 0,
                        width : '100%',
                    },
                    [on(350)] : {
                        
                        marginLeft : 0,
                        width : 350,
                    }
                })}>

                    
                    { productAdded && 
                        <>
                            <ProductAddedPage 
                                purchaseId={props.purchaseId}
                                product={productAdded} 
                                productName={productName} 
                                setBreadcrumb={setBreadcrumb}
                            />
                        </> 
                    }

                    { !productAdded && 
                        <>
                            <ProductLanding
                                cardParams={cardParams} 
                                optionsBoxScale={optionsBoxScale}
                                setPopup={setPopup}
                                setShoppingCartLength={setShoppingCartLength}
                                setCardParams={setCardParams}
                                setProductAdded={setProductAdded}
                                purchaseId={props.purchaseId}
                            />
                                    
                            <LineDividerSpacer top={100} bottom={100}/>
                            
                            <ProductFeatures cardParams={cardParams}/>
                        </> 
                    }

                </div>
                    
                <PurchaseHeader
                    cardParams={cardParams}
                    setPopup={setPopup}
                />
                
                <PopupWrapper
                    purchaseId={props.purchaseId}
                    cardParams={cardParams}
                    setPopup={setPopup}
                    setShoppingCartLength={setShoppingCartLength}
                    setProductAdded={setProductAdded}
                    popup={popup}
                />
            </Container>
        </>
        
    )
}

export default ProductPage;







const PurchaseHeader = ( props : {
    setPopup : ( value : any ) => void,
    cardParams : any
}) => {

    const PurchaseButton = () => {
        return (
            <Button
                onClick={()=>{
                    
                    if( props.setPopup )
                        props.setPopup({ 
                            toggle : true, 
                            title : 'Confirm',
                            children: <>
                                <ProductPopup
                                    cardValuesFront={props.cardParams.front}
                                    cardValuesBack={props.cardParams.back}
                                    cardColors={props.cardParams.colors}
                                    cardLogo={props.cardParams.logo}
                                />
                            </> 
                        })  
                }}
                style={{
                    marginTop : -5, 
                    width : 200, 
                    height : 50,
                    backgroundColor : 'black', 
                }}            
            >
                <p style={{ color : 'white', fontWeight : 600, fontSize : 18 }}>Add to Cart</p>
            </Button>
        )
    }

    const HeaderScroll = ( props : { y : number, hide : number, cardParams : any, hideB4 ?: number  }) => {
        return (
            <HeaderOnScrollWrapper y={props.y} hideOn={props.hide} hideB4={props.hideB4} h={80}>
                <div {...css({
                    width : '100vw',
                    height : '100%',
                    backgroundColor : 'white',
                    borderBottom : '1px solid rgba(0,0,0,0.1)',
                    marginTop : -1,
                })}>
                    
                    
                    <X>
                        <div style={{ maxWidth : 700 }}>
                            <div style={{ float : 'left'}}>
                                <ProductRating
                                    container={{ marginLeft : 20 }}
                                    title={props.cardParams.info.title} 
                                    subtitle={props.cardParams.info.subtitle} 
                                    ratings={props.cardParams.info.ratings}
                                />
                            </div>

                            <div style={{ float : 'right'}}>
                                <SpacerV h={20 }/>
                                <PurchaseButton/>
                            </div>
                        </div>
                </X>
                                
                </div>
            </HeaderOnScrollWrapper>
        )
    }

    const case1 = { hide : 849, y : 270, cardParams: props.cardParams   }
    const case2 = { hide : 600, y : 620, hideB4 : 849, cardParams: props.cardParams   }

    return (
        <>
            <HeaderScroll {...case1}/>
            <HeaderScroll {...case2}/>
        </>
    )
}

const Container = ( props : { children ?: any , breadcrumb ?: { value : string, url : string }[] }) => {
    const [loggedIn, setLoggedIn ] = React.useState(false)
    return (
        <>  
            <AuthManager noredirect setLoggedIn={setLoggedIn}/>
            <CobraLayout headerType="store" breadcrumb={ props.breadcrumb } loggedIn={loggedIn}>
                { props.children}
                
            </CobraLayout>
        </>
    )
}

const PopupWrapper = ( props : { 
    cardParams : any , 
    popup : any , 
    setPopup : ( value : any ) => void,
    setShoppingCartLength : ( value : number ) => void, 
    setProductAdded : ( value : any ) => void
    purchaseId ?: string
}) => {

    const popup = props.popup
    const cardParams = props.cardParams
    return (
        <>
            <PopupContainer 
                
                //title="Here is the title"
                popup={popup} 
                setPopup={props.setPopup}
                buttons={[
                    { value : 'Cancel', onClick : () => { 

                        props.setPopup({ 
                            ...popup, 
                            toggle : false 
                        })
                    }},
                    { value : 'OK', onClick : () => { 



                        
                        const product : any = {
                            ...cardParams.product,
                            img : cardParams.images.back,
                            order : {
                                link : getRandomString(),
                                id : cardParams.id,
                                name : cardParams.name,
                                desc : cardParams.desc,
                                colors : stripStyleFromCusomizationObject(cardParams.colors),
                                front : stripStyleFromCusomizationObject(cardParams.front),
                                back : stripStyleFromCusomizationObject(cardParams.back),
                                logo : {
                                    src : cardParams.logo.src,
                                    width : 50,
                                    height : 50,
                                }
                            }
                        }
                        
                        if( ENABLE_ERROR_LOGGING ) console.log('adding... ' )
                        if( ENABLE_ERROR_LOGGING ) console.log( product )
                        //if( ENABLE_ERROR_LOGGING ) console.log( cardParams )
                        addToShoppingCart(product, props.purchaseId )
                        props.setShoppingCartLength(getShoppingCartLength())
                        props.setPopup({ ...popup, toggle : false })
                        setTimeout(()=>{
                            props.setProductAdded( product )
                        },300)
                    }}
                ]}
            />
        
        </>
    )
}