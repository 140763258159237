// npm i @fontsource/roboto --save
// import "@fontsource/roboto/900.css" 
// import "@fontsource/roboto/800.css" 
// import "@fontsource/roboto/700.css" 
// import "@fontsource/roboto/600.css" 
// import "@fontsource/roboto/500.css" 
// import "@fontsource/roboto/100.css" 
// import "@fontsource/roboto/300.css" 



export const getFontRoboto = () => {
    return {
        fontFamily: "Roboto",
        //fontWeight: 800
    }
}


