import React from 'react'
import { SectionTitle } from '../../../../client-ui/checkout/CheckoutSectionTitle'
import { TextInclusionSmall } from '../../../../client-ui/checkout/Typography'
import Stack from '../../../../locust/composables/Stack'
import X from '../../../../locust/composables/X'
import SpacerV from '../../../../locust/sections/SpacerV'
import { AutoAwesome } from '@styled-icons/material/AutoAwesome'
import { SectionFooterIcon } from '../../components/FeatureFooterIcon'
import IconNFC from "../../images/icon-nfc.svg";
import IconQR from "../../images/icon-qr.svg";
import IphoneWhite from '../../../../assets/images/svgs/iphone-white-300x580.svg'
import IphoneContactsScreen from '../../../../assets/images/svgs/iphone-contacts-300x580.svg'
import { PrimaryTextII } from '../../../../client-ui/shared/PrimaryText'
import { SecondaryTitleSubtitleII } from '../../../../client-ui/shared/SecondaryTitleSubtitle'
import { CardViewScaled } from '../../../../client-ui/products/ProductOptions'
import { getConfigDefaults } from '../../../../locust/composables/Layout'
import Scale from '../../../../locust/composables/Scale'
import { css } from 'glamor'


const TapToTransfer = ( props : {
    image : string,
    cardParams : any,
}) => {

    const cardBackParams = { 
        ...props.cardParams.back, 
        ...props.cardParams.colors,
        logo : { ...props.cardParams.logo },
    }

    const on = ( value : number ) => getConfigDefaults().onMedia( value )
 

    return (
        <>
            <div style={{ backgroundColor : ''}}>

                <SecondaryTitleSubtitleII align="center">
                    <>Tap to Transfer</>
                    <>Level Up.</>
                </SecondaryTitleSubtitleII>

                <X>
                <PrimaryTextII>
                    Slap Kard's SMART business card begins the next generation of tech savvy and eco-friendly business networking. Meet, greet, tap and share your contact deails instantly. No paper. No fuss. Zero carbon impact. 
                </PrimaryTextII>
                </X>
                <SpacerV h={40}/>
                <X>
                    <Stack>
                        <>
                            <Scale origin="top" scale={0.8}>
                                <div style={{ 
                                    overflow : 'hidden', 
                                    borderRadius : 8, 
                                    boxShadow: 'rgba(0, 0, 0, .55) 0px 5px 15px', 
                                }}>
                                    { props.cardParams.nodes.getBackNode(cardBackParams)}
                                </div>
                            </Scale>
                            

                        </> 
                        <>
                            <SpacerV h={86}/>
                            <X><Phone/></X>  
                        </>
                                        
                    </Stack>
                    
        
                </X>
            </div>
                     
            <SpacerV h={40}/>
            <X>
                
                <div style={{ width : 120 }}>
                    <SectionFooterIcon value={'Free Feature'} space={10} img={<AutoAwesome width={25} />}/>
                </div> 
                <div style={{ width : 120 }}>
                    <SectionFooterIcon value={'NFC Enabled'} space={10} img={<img width={25} height={25} src={IconNFC}/>}/>
                </div> 
                <div style={{ width : 120 }}>
                    <SectionFooterIcon value={'QR Scannable'} space={10} img={<img width={25} height={25} src={IconQR}/>}/>
                </div> 

            </X>  
            </>
        
        
    )
}


const Phone = ( props : { scale ?: number }) => {
    return (
        <div style={{ marginBottom: -124 }}>  
            <Scale scale={ props.scale ? props.scale : 0.8} origin="top">
            <div {...css({
                width : 300,
                height : 580,
                //backgroundColor : 'red',


            })}>
                <Stack>
                <div 
                    style={{ 
                        boxShadow: 'rgba(0, 0, 0, .55) 0px 5px 15px', 
                        marginTop : 8,
                        marginLeft : 15,
                        //marginLeft : ((300*0.8)-(273*0.8))/2 + 1,
                        width : 273, 
                        height : 558,
                        borderRadius : 50,
                    }}    
                />
            
                <img 
                    style={{  borderRadius : 8, marginTop : -4 }}
                    width={'100%'} 
                    height={'100%'} 
                    src={IphoneWhite}
                />
            
                <img 
                    style={{  borderRadius : 8, marginTop : -4 }}
                    width={'100%'} 
                    height={'100%'} 
                    src={IphoneContactsScreen}
                />
            </Stack>
            
            </div>
            </Scale>
        </div>

    )
}
export default TapToTransfer;