import { css } from "glamor"
import React from "react"
import { getFontPoppins } from "../../assets/fonts/FontPoppins"
import { getConfigDefaults } from "../../locust/composables/Layout"

const PrimaryText = ( props : { children ?: any, on?: number, style ?: Record<string,any> }) => {
    const on = ( value : number ) => getConfigDefaults().onMedia( value )
    const min : number = props.on ? props.on : 525
    return (
        <p {...css({ 
            ...getFontPoppins(), 
            maxWidth : 600, 
            width : 'calc(100% - 45px)',
            fontSize : 18, 
            fontWeight : 400, 
            letterSpacing : 0.8, 
            textAlign : 'center', 
            ...props.style,
            [ on( min )]:{
                fontSize : 15, 
            } 

            })}>
            { props.children}
        </p>
    )
}
export default PrimaryText;

export const PrimaryTextII = ( props : { children ?: any, on?: number, style ?: Record<string,any> }) => {
    const on = ( value : number ) => getConfigDefaults().onMedia( value )
    const min : number = props.on ? props.on : 525
    return (
        <p {...css({ 
            ...getFontPoppins(), 
            maxWidth : 600, 
            width : 'calc(100% - 45px)',
            fontSize : 15, 
            fontWeight : 400, 
            letterSpacing : 0.8, 
            textAlign : 'center', 
            ...props.style,
            [ on( min )]:{
                fontSize : 13, 
            } 

            })}>
            { props.children}
        </p>
    )
}