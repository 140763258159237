import { css } from 'glamor'
import React from 'react'
import { FACEBOOK_BLACK } from '../../assets/colors/Colors'
import { getFontMontserrat } from '../../assets/fonts/FontMontserrat'
import { getFontPoppins } from '../../assets/fonts/FontPoppins'
import { getFontRoboto } from '../../assets/fonts/FontRoboto'
import { getConfigDefaults } from '../../locust/composables/Layout'
import { getChildren } from '../../locust/composables/Shared'
import X from '../../locust/composables/X'

const SecondaryTitleSubtitle = ( props : { children ?: any, mobile?: number, min?: number }) => {

    const children : any[] = getChildren(props.children)
    const config = getConfigDefaults()
    const on = ( value : number ) => { return config.onMedia( value )}
    const mobile : number = props.mobile ? props.mobile : 525
    const min : number = props.min ? props.min : 400
    return (
        <>  
            { children.length > 0 && 
                <SecondaryTitle style={{
                    textAlign : 'center',
                    [on( mobile )] : {
                        marginLeft : 50, 
                        width : 'calc(100% - 100px)',
                        backgroundColor : '',
                        fontSize : 16,
                        lineHeight : 1.5,
                    }

                }}>
                    <X>
                        { children[0] }
                    </X>
                </SecondaryTitle>
            }
            
            { children.length > 1 && 
                <SecondarySubtitle style={{
                    textAlign : 'center',
                    [on( mobile )] : {
                        marginTop : -6, 
                        marginLeft : 20, 
                        width : 'calc(100% - 40px)',
                        backgroundColor : '',
                        fontSize : 35,
                    },
                    [on( min )] : {
                        marginTop : 4,
                        fontSize : 28,
                    }
                }}>
                    <X>
                          { children[1] }
                    </X>      
                </SecondarySubtitle>
            }


            
        </> 
    )
}

export default SecondaryTitleSubtitle;



export const SecondaryTitleSubtitleII = ( props : { children ?: any, mobile?: number, min?: number, align ?: string }) => {

    const children : any[] = getChildren(props.children)
    const config = getConfigDefaults()
    const on = ( value : number ) => { return config.onMedia( value )}
    const mobile : number = props.mobile ? props.mobile : 525
    const min : number = props.min ? props.min : 400
    return (
        <>  
            { children.length > 0 && 
                <SecondaryTitle style={{
                    textAlign : props.align ? props.align : 'left',
                    fontSize : 20, 
                    fontWeight : 600,
                    [on( mobile )] : {
                        marginLeft : 50, 
                        width : 'calc(100% - 100px)',
                        backgroundColor : '',
                        fontSize : 14,
                        lineHeight : 1.5,
                    },
                    [on( min )] : {
                        marginTop : 4,
                        fontSize : 12,
                    }

                }}>
                    
                        { children[0] }
                    
                </SecondaryTitle>
            }
            
            { children.length > 1 && 
                <SecondarySubtitle style={{
                    textAlign : props.align ? props.align : 'left',
                    fontSize : 36, 
                    fontWeight : 800,
                    marginTop : 2,
                    [on( mobile )] : {
                        marginTop : -2, 
                        marginLeft : 20, 
                        width : 'calc(100% - 40px)',
                        backgroundColor : '',
                        fontSize : 30,
                        marginBottom : 6,
                    },
                    [on( min )] : {
                        fontSize : 24,
                    }
                }}>
                    
                          { children[1] }
                    
                </SecondarySubtitle>
            }


            
        </> 
    )
}



export const SecondaryTitle = ( props : { children ?: any, style ?: Record<string,any> }) => {
    return (
        <h1 {...css({
            ...getFontPoppins(),
            color : FACEBOOK_BLACK,
            fontSize : 25, 
            fontWeight : 600,
            ...props.style
        })}>
            {props.children}
        </h1>
    )
}

export const SecondarySubtitle = ( props : { children ?: any, style ?: Record<string,any> }) => {
    return (
        <h2 {...css({
            ...getFontPoppins(),
            marginTop : -10, 
            color : FACEBOOK_BLACK,
            fontSize : 50, 
            fontWeight : 800,
            ...props.style,
        })}>
            {props.children}
        </h2>
    )
}


