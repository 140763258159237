import * as React from "react";

const LogoWhiteLime = (props : any ) => (
  <svg
    id="logo-white-lime_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 50 50"
    style={{
      enableBackground: "new 0 0 50 50",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{`.logo-white-lime_svg__st1{fill:${ props.textColor ? props.textColor : '#abea31'}}`}</style>
    <g id="logo-white-lime_svg__svgg_00000036223754513710915210000007076277672124143795_">
      <path
        id="logo-white-lime_svg__path0_00000020374255716406323430000008844327461067400372_"
        d="M13.8.1c-3.1.5-5.6 2.7-6.1 5.6-.2.9-.2 22.5 0 23.2.4 1.9 2.1 3.4 4.2 3.8.8.1 6 .1 6.6-.1 1-.3 1.7-.6 2.4-1.3l.5-.4.5.4c.8.7 1.5 1 2.4 1.3.7.2 6.1.2 6.9 0 1.3-.3 2.7-1.2 3.3-2.2l.2-.4s.2.2.4.5c1.3 1.5 4.5 2.6 5.9 2 .8-.4.8-1-.2-2-.4-.5-1-1.4-1.2-2.1-.2-.5-.2-.6-.2-6.8 0-5.5-.1-6.3-.2-6.6-.7-1.9-2.1-3.2-4.2-3.5-.8-.1-3.8-.1-4.4.1-1 .3-1.7.6-2.4 1.3l-.5.4-.4-.3c-.8-.7-1.4-1-2.4-1.3-.6-.2-5.7-.2-6.5-.1-.7.1-1.4.4-1.9.7l-.4.2V9.6H19c3.1 0 3.2 0 3.9-.3.5-.2 1.2-.8 1.4-1.3l.2-.3h1.8c2.3 0 2.7-.1 2.7-1 0-.8-.4-1-2.6-1h-1.7v-.2c-.7-2.9-3-5-6.2-5.5-1 0-3.9 0-4.7.1m4.7 2c2.3.6 3.8 2.3 4 4.3.1.6-.1.9-.5 1.2-.3.1-.3.1-3.7.1-3.9 0-3.8 0-4.1.5-.2.4-.2 17.9 0 18.3.4.7 1.5.7 1.8 0 .1-.2.1-.4.1-5.3 0-6.1 0-6 1-6.9.9-.8 1.3-.9 4.4-.9 3 0 3.5.1 4.4.9 1 .9 1 .8 1 6.9 0 4.9 0 5.1.1 5.3.4.7 1.5.7 1.8 0 .1-.2.1-.4.1-5.3 0-6.1 0-6 1-6.9.8-.7 1.4-.9 3.3-.9s2.5.2 3.3.9c1 .9 1 .8 1 7.6 0 6.7 0 6.9.7 8.4.1.2-.5-.1-1-.7-.8-.7-1.3-1.7-1.6-3.3-.1-.6-.2-.9-.2-4.5s0-3.9-.2-4.1c-.4-.7-1.5-.6-1.8 0-.1.2-.1.4-.1 5.3 0 6.1 0 6-1 6.9-.9.8-1.3.9-4.4.9s-3.5-.1-4.4-.9c-1-.9-1-.8-1-6.9 0-4.9 0-5.1-.1-5.3-.4-.7-1.5-.7-1.8 0-.1.2-.1.4-.1 5.3 0 3.2 0 5.2-.1 5.4-.2 1.2-1.3 2.1-2.6 2.3-.5.1-4.9.1-5.4 0-1.3-.2-2.3-1.2-2.6-2.3-.1-.7-.1-22.6.1-23 .6-1.8 2.2-3.2 4-3.5.1.1.4.1.4.1.4-.1 3.8 0 4.2.1m.3 1.9c-.7.3-.7 1.3 0 1.7.5.2 1.2.1 1.4-.4.5-.9-.5-1.8-1.4-1.3"
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: props.color ? props.color : 'white',
        }}
      />
    </g>
    { !props.noSubtitle && <path
      className="logo-white-lime_svg__st1"
      d="M14.4 42v1.7c0 2-1 3.2-3 3.2s-3-1.2-3-3.2v-6.6c0-2 1-3.2 3-3.2s3 1.2 3 3.2v1.2h-1.9V37c0-.9-.4-1.3-1-1.3s-1 .3-1 1.3v6.9c0 .9.4 1.2 1 1.2s1-.3 1-1.2V42h1.9zM15.1 37.1c0-2 1.1-3.2 3-3.2s3 1.2 3 3.2v6.6c0 2-1.1 3.2-3 3.2s-3-1.2-3-3.2v-6.6zm2 6.7c0 .9.4 1.3 1 1.3s1-.3 1-1.3V37c0-.9-.4-1.3-1-1.3s-1 .3-1 1.3v6.8zM28 37v.5c0 1.3-.4 2.1-1.3 2.6 1.1.4 1.5 1.4 1.5 2.7v1c0 2-1 3-3 3h-3.1V34h3c2 0 2.9 1 2.9 3zm-3.9-1.1v3.4h.8c.7 0 1.2-.3 1.2-1.3v-.7c0-.9-.3-1.3-1-1.3h-1zm0 5.1v3.9h1.1c.7 0 1-.3 1-1.3v-1.1c0-1.2-.4-1.5-1.3-1.5h-.8zM33.2 46.7c-.1-.3-.2-.5-.2-1.6v-2c0-1.2-.4-1.6-1.3-1.6H31v5.2h-2V34h3c2.1 0 3 1 3 2.9v1c0 1.3-.4 2.2-1.3 2.6 1 .4 1.3 1.4 1.3 2.7v2c0 .6 0 1.1.2 1.5h-2zm-2.1-10.8v3.9h.8c.7 0 1.2-.3 1.2-1.3v-1.3c0-.9-.3-1.3-1-1.3h-1zM42.5 46.7h-2l-.3-2.3h-2.5l-.3 2.3h-1.8l2-12.7h2.9l2 12.7zm-4.6-4h1.9l-1-6.4-.9 6.4z"
    />}
    
  </svg>
);

export default LogoWhiteLime;

