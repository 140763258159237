import React from 'react'
import { TextInclusionSmall } from '../../client-ui/checkout/Typography'
import { TwoColumn } from '../../locust/components/Column'
import { LineDividerSpacer } from '../../locust/components/LineDivider'
import X from '../../locust/composables/X'
import XY from '../../locust/composables/XY'
import Y from '../../locust/composables/Y'
import BoxTitle from '../shared/BoxTitle'


// [TO-DO]
// this is the popup that appears right after the add-to-cart button is clicked. Not all products 
// require a popup therefore this should be refactored to exist within the invidual prodect data files.

const ProductPopup = ( props : { 
    cardValuesFront ?: any,
    cardValuesBack ?: any,
    cardColors?: any,
    cardLogo?: any, 
}) => {
    return (
        <>
            
                <div style={{ height : 'auto', width : 400  }}>
                    <BoxTitle align="left"><p style={{ fontSize : 25 }}>Front Side</p></BoxTitle>
                    { props.cardValuesFront !== undefined && 
                        Object.keys(props.cardValuesFront).map(( keyValue : string)=>{
                            return <>
                                <TwoColumn
                                    left={
                                        <>
                                            <TextInclusionSmall style={{ fontSize : 12, fontWeight : 600 }}>
                                                {keyValue}
                                            </TextInclusionSmall>
                                        </>
                                    }
                                    right={
                                        <TextInclusionSmall style={{ fontSize : 12 }}>
                                            {props.cardValuesFront[keyValue].value}
                                        </TextInclusionSmall>
                                    }
                                />
                            
                            </>
                        })
                    }

                    
                    <LineDividerSpacer top={10} bottom={10}/>
                    
                    <BoxTitle align="left"><p style={{ fontSize : 25 }}>Back Side</p></BoxTitle>
                    
                    
                    { 
                        Object.keys(props.cardValuesBack).map(( keyValue : string)=>{
                            return <>
                                <TwoColumn
                                    left={
                                        <>
                                            <TextInclusionSmall style={{ fontSize : 12, fontWeight : 600 }}>
                                                {keyValue}
                                            </TextInclusionSmall>
                                            
                                        </>
                                    }
                                    right={
                                        <>
                                            <TextInclusionSmall style={{ fontSize : 12 }}>
                                                {props.cardValuesBack[keyValue].value}
                                            </TextInclusionSmall>
                                        
                                        </>
                                    }
                                />
                            
                            </>
                        })
                    }

                    <LineDividerSpacer top={10} bottom={10}/>
                    
                    <BoxTitle align="left"><p style={{ fontSize : 25 }}>Colors & Logo</p></BoxTitle>
                    
                    
                    
                    
                    {
                    
                        Object.keys(props.cardColors).map(( keyValue : string)=>{
                            return <>
                                <TwoColumn
                                    container={{ paddingTop : 4 }}
                                    left={

                                        <div style={{ 
                                            width : '100%', 
                                            height : 40, 

                                        }}>
                                            <Y align="left">
                                                <TextInclusionSmall style={{ fontSize : 12, fontWeight : 600 }}>
                                                    {keyValue}
                                                </TextInclusionSmall>
                                            </Y>
                                        </div>
                                    }
                                    right={
                                        <XY>
                                            <div style={{ 
                                                width : 40, 
                                                height : 40, 
                                                backgroundColor : props.cardColors[keyValue].value, 
                                                border: '1px solid silver', 
                                                borderRadius : 4
                                            }}/>
                                        </XY>
                                    }
                                />
                            
                            </>
                        })
                    }
                    
                    <TwoColumn
                        container={{ paddingTop : 4 }}
                        left={
                            <div style={{ 
                                width : '100%', 
                                height : 40, 

                            }}>
                                <Y align="left">
                                    <TextInclusionSmall style={{ fontSize : 12, fontWeight : 600 }}>            
                                        Logo
                                    </TextInclusionSmall>
                                </Y>
                            </div>
                        }
                        right={
                            <>  
                                <X>
                                    <img 
                                        src={props.cardLogo.src}
                                        width={40}
                                        height={40}
                                    />
                                </X>
                                
                            </>
                        }
                    />
                </div>
            
        
        </>
    )
}
export default ProductPopup;