import React from 'react'
import { SectionTitle } from '../../../client-ui/checkout/CheckoutSectionTitle'
import X from '../../../locust/composables/X'
import SpacerV from '../../../locust/sections/SpacerV'

export const  SectionFooterIcon = ( props : { value : string, img : React.ReactNode, space ?: number, container ?: Record<string,any>}) => {
    return(
        <div style={props.container}>
            
                <X>{props.img}</X>
                { props.space && <SpacerV h={props.space}/>}
                <SectionTitle style={{ width : 100 , marginTop : -20 }}>
                    <p style={{ fontSize : 12, fontWeight : 500, letterSpacing : 0.8, textAlign : 'center' }}>
                        { props.value }
                    </p>
                </SectionTitle> 
            
        </div>
    )
}