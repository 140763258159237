
import { css } from 'glamor'
import React from 'react'
import X from '../../locust/composables/X'
import SpacerV from '../../locust/sections/SpacerV'
import { SectionTitle } from '../checkout/CheckoutSectionTitle'
import NoticeAndLink from '../shared/NoticeAndLink'
import ProductAdded from './ProductAdded'

const ProductAddedPage = ( props : { children ?: any, 
    product?: any  
    productName?: string,
    setBreadcrumb ?: any 
    purchaseId ?: string

}) => {

    React.useEffect(()=>{

        props.setBreadcrumb([
            { value : 'Home', url : '' },
            { value : 'Products', url : '' },
            { value : props.productName, url : '' },
            { value : 'Added to Cart', url : ''}
         ])

    },[])

    
    const productData : any = {
    
    
        desc: props.product.desc,
        name: `${props.product.name}`,
        product: props.product,
        total: String(Number(props.product.price))
    }

    return (
        <>  

            <SpacerV h={50}/>
            <X>
                <SectionTitle style={{ width : '100%', marginTop : -20}}>
                    <p style={{ fontSize : 36, fontWeight : 800 }}>Item Added to Cart</p>
                </SectionTitle>
            </X>    
            
            <X>
                <div {...css({
                    width : '100%',
                    marginLeft : 8,
                    maxWidth : 800,
                })}></div>
            </X>   

            <ProductAdded orderData={productData} purchaseId={props.purchaseId}/>

            
            <NoticeAndLink
                value={'Go to Checkout'}
                href={'/checkout'}>
                    Your item was added.
            </NoticeAndLink>
                {/*
            <SpacerV h={300}/>
            Product Added
            {JSON.stringify(props.product)}
             */}
            
        </>
    )
}

export default ProductAddedPage;