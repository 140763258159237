import React from 'react'
import X from '../../../../locust/composables/X'
import SpacerV from '../../../../locust/sections/SpacerV'
import { SectionFooterIcon } from '../../components/FeatureFooterIcon'
import FeatureProductLR from '../../components/FeatureProductLR'
import SocialShareImage from '../../../../assets/images/svgs/iphone-social-share-300x580.svg'
import { Facebook } from '@styled-icons/boxicons-logos/Facebook';
import { Linkedin } from '@styled-icons/entypo-social/Linkedin';
import { Twitter } from '@styled-icons/entypo-social/Twitter';
import { CreditCard2BackFill } from '@styled-icons/bootstrap/CreditCard2BackFill'
import { Premium } from '@styled-icons/fluentui-system-filled/Premium'
import Hide from '../../../../locust/composables/Hide'
import Scale from '../../../../locust/composables/Scale'
import Inline from '../../../../locust/composables/Inline'
// import { InstagramAlt } from '@styled-icons/boxicons-logos/InstagramAlt';

const content = {
    title : 'Social Share',
    subtitleLine1 : 'A picture is',
    subtitleLine2 : 'worth 1000 words.',
    description: "Slap Kard's SMART business card begins the next generation of tech savvy and eco-friendly business networking. Meet, greet, tap and share your contact deails instantly. No paper. No fuss. Zero carbon impact.",
    bullets:[
        { bullet : '01', value : `Slap Kard's SMART business card introduces the next generation of tech savvy and eco-friendly business networking.`},
        { bullet : '02', value : `Slap Kard's SMART business card introduces the next generation of tech savvy and eco-friendly business networking.`},
        { bullet : '03', value : `Slap Kard's SMART business card introduces the next generation of tech savvy and eco-friendly business networking.`},
    ]
}

export const getSocialShare = ( props : any ) => {
    return <SocialShare {...props}/>
}

const socialShareFooterIcons = [
    {
        value : 'Facebook',
        img : <Facebook width={30} />,
        style : { position : 'absolute' },
    },
    {
        value : 'LinkedIn',
        img : <Linkedin width={25} />,
        style : { position : 'absolute' },
    },
    {
        value : 'Twitter',
        img : <Twitter width={25} />,
        style : { position : 'absolute' },
    },
    {
        value : 'Premium Feature',
        space : 10,
        img : <Premium width={25} />
    },
    {
        value : 'Subscription Required',
        img : <CreditCard2BackFill width={25}/>,
        space : 10,
    },
]

export const SocialShare = ( props : {
    right ?: boolean 
}) => {
    return (
        <>
            <FeatureProductLR
                right={props.right}
                title={content.title}
                subtitleLine1={content.subtitleLine1}
                subtitleLine2={content.subtitleLine2}
                description={content.description}
                bullets={content.bullets}
                image={
                    <>
                        
                        <img 
                            style={{  borderRadius : 8, marginBottom : 180  }}
                            width={300*.9} 
                            height={580*0.9} 
                            src={SocialShareImage}
                        />
                        
                        
                    </>
                }
            />

            <SpacerV h={40}/>

            <div style={{ textAlign : 'center'}}> 
                <FeaterFooterIcons
                    data={socialShareFooterIcons}
                />
            </div> 
        
        </>
    )
}

interface FooterIcon {
    img : React.ReactNode,
    value : string,
    space?: number, 
    style ?: Record<string,any>
}

const FeaterFooterIcons = ( props : {
    data : FooterIcon[]

    

}) => {
    
    const nodes = props.data.map(( icon : FooterIcon )=>{
        return (
            <div style={{ width : 120, display : 'inline-block' }}>
                <SectionFooterIcon value={icon.value} space={icon.space} img={icon.img }/>
            </div> 
        )
    })

    const scaledNodes = props.data.map(( icon : FooterIcon )=>{
        return (
            <Inline>
                <Scale scale={0.8}>
                    <div style={{ width : 120, height : 70  }}>
                        <SectionFooterIcon value={icon.value} space={icon.space} img={icon.img }/>
                    </div> 
                </Scale>
            </Inline>
        )
    })

    return (
        <> 
            <X>
            <Hide on={650}>
                    {nodes}
            </Hide>
            <Hide b4={650}>
                <div style={{ marginLeft : 50 }}>
                {scaledNodes}
                </div>
            </Hide>
            </X>


        </>
    )
}