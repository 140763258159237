import { css } from "glamor"
import React from "react"
import { getFontPoppins } from "../../assets/fonts/FontPoppins"
import { GlobalConfig, validateConfig } from "../../locust/composables/Layout"
import X from "../../locust/composables/X"

const ProductTitleRsp = ( props : {
    children ?: any
    c ?: string
    style?: Record<string,any>
    left ?: boolean
    }) => {
    const config : GlobalConfig = validateConfig()
    const onBreakpoint : string = config.onMedia(config.BREAKPOINT)
    const style = {
        ...getFontPoppins(), 
        fontWeight : 600,
        color : props.c ? props.c : 'white',
        fontSize : 20,
        [ onBreakpoint ] : { 
        //fontSize : 16,
        },
        ...props.style
    }


    return <p {...css(style)}>
        {props.left 
            ?   <X>
                    <p style={{ textAlign : 'left', width : '100%'}}>{props.children}</p>
                </X> 
            :   <X>{props.children}</X> 
        }
    </p>
}

export default ProductTitleRsp;
