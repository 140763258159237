
import React from 'react'
import X from '../../../../locust/composables/X'
import SpacerV from '../../../../locust/sections/SpacerV'
import { SectionFooterIcon } from '../../components/FeatureFooterIcon'
import FeatureProductLR from '../../components/FeatureProductLR'
import SaveTrees466x750 from '../../../../assets/images/save-trees-466x750.png'
import { Leaf } from '@styled-icons/entypo/Leaf'
import { Factory} from '@styled-icons/boxicons-solid/Factory'
import { Recycle } from '@styled-icons/fa-solid/Recycle'


const content = {
    title:"Reusablility",
    subtitleLine1:"A single card",
    subtitleLine2:"to rule them all.",
    description:" Never buy another business card again. Our fully reusable cards save you money. Even more, they save the world from losing an estimated seven million trees per year, 90 percent of which end up in the trash.",
    bullets:[
        { 
            bullet : '01', 
            value : 'A card scanned is a card saved. Slap Kard\'s Dashboard shows you in real time how much green you are saving. It might suprise you.'
        }
    ]
}

const EcoReusability = ( props : {
    right ?: boolean

}) => {
    return (
        <>
            <FeatureProductLR
                right={props.right}
                title={content.title}
                subtitleLine1={content.subtitleLine1}
                subtitleLine2={content.subtitleLine2}
                description={content.description}
                bullets={content.bullets}
                image={
                    <div style={{
                        marginBottom : 206,
                        marginTop : 30,
                        width: 300*0.8,
                        height: 400*0.8,
                        //backgroundColor : 'blue'
                    }}>
                        <img 
                            width={249*0.8} 
                            height={400*0.8} 
                            src={SaveTrees466x750}
                        />
                    </div>
                }
            />
            <SpacerV h={40}/>
            <X>
                <div style={{ width : 120 }}>
                    <SectionFooterIcon value={'Save the Rainforest'} space={10} img={<Leaf width={25} />}/>
                </div> 

                <div style={{ width : 120 }}>
                    <SectionFooterIcon value={'Go Green'} img={<Recycle width={25} />}/>
                </div>

                <div style={{ width : 120 }}>
                    <SectionFooterIcon value={'Reduce Emissions'} space={10} img={<Factory width={25} />}/>
                </div>
                
            </X> 
        </>
    )
}
export default EcoReusability;