import React from 'react'
import { LineDividerSpacer } from '../../locust/components/LineDivider'
import { getRandomString } from '../../locust/composables/Shared'
import SpacerV from '../../locust/sections/SpacerV'
import DynamicQR from '../../products/shared/features/v1/DynamicQR'
import EcoReusability from '../../products/shared/features/v1/EcoReusability'
import IntroWhatsIncluded from '../../products/shared/features/v1/IntroWhatsIncluded'
import { SocialShare } from '../../products/shared/features/v1/SocialShare'
import TapToTransfer from '../../products/shared/features/v1/TapToTransfer'

const ProductFeatures = ( props : { cardParams : any }) => {
    const cardParams : any = props.cardParams
    return (
        <>  
            { cardParams.features.map(( item : any, index : number )=>{
                
                return <div key={getRandomString()}>
                    {getProductFeatureById( item.id, {...item.props, cardParams : cardParams } )}
                    <LineDividerSpacer top={30} bottom={60}/>
                </div>
                

            })}
            <SpacerV h={200}/>
        </>
    )    
}

export default ProductFeatures;

export const getProductFeatureById = ( id : string, props ?: any ) => {
    if('intro-included-v1' === id) return <IntroWhatsIncluded {...props}/>
    if('tap-to-transfer-v1' === id) return <TapToTransfer {...props}/>
    if('dynamic-qr-v1' === id) return <DynamicQR {...props}/>
    if('eco-reusability-v1' === id) return <EcoReusability {...props}/>
    if('social-share-v1' === id) return <SocialShare {...props}/>
    
    else return <></>
}
