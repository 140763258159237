import { motion } from 'framer-motion'
import React from 'react'
import DisplayCubeQuad from '../../assets/images/svgs/display-cube-gray-500x400.svg'
import DisplayCubeSingle from '../../assets/images/svgs/display-cube-gray-single-250x280.svg'
import Stack from '../../locust/composables/Stack'




export const AnimCardCustomization = ( props : { front ?: string, back ?: string, scale ?: number }) => {

    const [blockOneRise, setBlockOneRise]   = React.useState(false)
    const [blockTwoRise, setBlockTwoRise]   = React.useState(false)
    const [cardDropOne, setCardDropOne]     = React.useState(false)
    const [cardDropTwo, setCardDropTwo]     = React.useState(false)

    React.useEffect(()=>{
        setBlockOneRise(true)
        setTimeout(()=>{
            setBlockTwoRise(true)
        },300)
        setTimeout(()=>{
            setCardDropOne(true)
        },700)

        setTimeout(()=>{
            setCardDropTwo(true)
        },1000)
    },[])
    const scale : number = props.scale !== undefined ? props.scale : 0.75
    const scale2 : number = 0.85
    const scale1 : number = 0.95
    return (
        <>  
        <div style={{ backgroundColor : '', width : 500*scale,  height: 400*scale}}>
         <motion.div initial={{ scale : scale, transformOrigin : 'top left' }}>
         <div style={{ width : 500, height : 400, overflow : 'hidden'}}>
        
            <Stack>
                <>
            { blockTwoRise && 
                <motion.div                 
                    initial={{ y : 200 }}
                    animate={{ y : -80 }}
                    transition={{ duration: 0.5 }}
                    onAnimationComplete={()=>{
                        
                    }}
                >   
                <img
                    style={{ backgroundColor : '', marginLeft : -180, marginTop : 200 }}
                    src={DisplayCubeSingle} 
                    width={500*scale2} 
                    height={400*scale2}
                /> 
                </motion.div>
            }
            </>
            <>
            { blockOneRise &&
             <motion.div                 
                initial={{ y : 200 }}
                animate={{ y : -80 }}
                transition={{ duration: 0.5 }}
                onAnimationComplete={()=>{
                    
                }}
            >                 
                <img
                    style={{ backgroundColor : '', marginLeft : 85, marginTop : 280 }}
                    src={DisplayCubeSingle} 
                    width={500*scale1} 
                    height={400*scale1}
                /> 
            </motion.div>
            
            }   
            </>


            <>
            { cardDropOne && 
            
                
                <motion.div                 
                    initial={{ y : -100 }}
                    animate={{ y : 120 }}
                    transition={{ duration: 0.3 }}
                    onAnimationComplete={()=>{
                    }}
                >   
                <img
                    style={{ backgroundColor : '', marginLeft : -200, marginTop : -30 }}
                    src={props.back} 
                    width={350 * scale2 * 0.75} 
                    height={200 * scale2 * 0.75}
                /> 
                    
                </motion.div>

            }
            </>


            <>
            { cardDropTwo && 
            
                
                <motion.div                 
                    initial={{ y : -100 }}
                    animate={{ y : 170 }}
                    transition={{ duration: 0.3 }}
                    onAnimationComplete={()=>{
                    }}
                >   
                <img
                    style={{ backgroundColor : '', marginLeft : 135, marginTop : 0 }}
                    src={props.front} 
                    width={350 * scale2 * 0.75} 
                    height={200 * scale2 * 0.75}
                /> 
                    
                </motion.div>

            }
            </>


            

           

            </Stack>

        </div>
                {/*
            
                <div style={{ width : '100%', height : 500, marginTop : 0, overflow : 'hidden', backgroundColor : '' }}>
                    { blockTwoRise && 
                        <>
                            <motion.div                 
                                initial={{}}
                                animate={{ y : -280 }}
                                transition={{ duration: 0.7 }}
                                onAnimationComplete={()=>{
                                    
                                }}
                            >   
                            </motion.div>
                                <img
                                    style={{ backgroundColor : '', marginTop : 430 }}
                                    src={DisplayCubeSingle} 
                                    width={250*1.25} 
                                    height={280*1.25}
                                /> 
                            </motion.div>
                    
                        </> 
                    }
                    
                    { blockOneRise &&
                        <>
                            <motion.div                 
                                initial={{}}
                                animate={{ y : -300 }}
                                transition={{ duration: 0.5 }}
                                onAnimationComplete={()=>{

                                }}
                            >   
                                <img
                                    style={{ backgroundColor : '', marginLeft : ((250*1.25)/2)-8,  marginTop : 530 }}
                                    src={DisplayCubeSingle} 
                                    width={250*1.38} 
                                    height={280*1.38}
                                /> 
                            </motion.div>
                        
                        
                        </>
                    }

                    { cardDropOne && 
                        <>
                            
                            <motion.div                 
                                initial={{}}
                                animate={{ y : 394 }}
                                transition={{ duration: 0.5 }}
                                onAnimationComplete={()=>{
                                }}
                            >   
                                
                                <StaticImage
                                    style={{ backgroundColor: '', marginLeft: 40, marginTop: -270,  boxShadow: 'rgba(0, 0, 0, .55) 0px 5px 15px' }}
                                    src={ "../../assets/images/svgs/business-card-dark-color-350x200-md.png"}
                                    //src={BusinessCardBlackStaticSM}
                                    placeholder={"blurred"}
                                    quality={100}
                                    width={350 * 0.6}
                                    height={200 * 0.6}
                                    loading={"eager"} 
                                    alt={''}
                                />
                            </motion.div>
                           
                    
                        </>
                    }

                    { cardDropTwo && 
                        <>
                            <motion.div                 
                                initial={{}}
                                animate={{ y : 485 }}
                                transition={{ duration: 0.6 }}
                                onAnimationComplete={()=>{
                                }}
                            >   
                             
                                <StaticImage
                                    style={{ backgroundColor: '', marginLeft: 220, marginTop: -270,  boxShadow: 'rgba(0, 0, 0, .55) 0px 5px 15px' }}
                                    //src={props.back ? props.back : BusinessCardBlack}
                                    src={"../../assets/images/svgs/business-card-dark-color-350x200-md.png"}
                                    width={350 * 0.6}
                                    quality={100}
                                    height={200 * 0.6}
                                    placeholder={"blurred"}
                                    loading={"eager"} 
                                    alt={''}
                                />
                            </motion.div>
                            

                        </>
                    }
                    
                </div>
                */}
            </motion.div> 
            </div>   
        </>
    )
}