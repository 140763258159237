
import { css } from "glamor"
import React from "react"
import StarRating from "../../locust/components/StarRating"
import Inline from "../../locust/composables/Inline"
import X from "../../locust/composables/X"
import SpacerV from "../../locust/sections/SpacerV"

import { ItemPrice } from "../checkout/Typography"
import BoxTitle from "../shared/BoxTitle"
import ProductTitleRsp from "./ProductTitleRsp"



const ProductRating = ( props : {
    title : string, 
    subtitle : string, 
    ratings : any
    container ?: Record<string,any>

}) => {


    const subtitle = (
        <ItemPrice 
            style={{ 
                marginTop : -4, 
                marginLeft : 4, 
                fontSize : 14, 
                letterSpacing : 0.2, 
                display : 'inline-block' 
            }}
        >
            {props.subtitle}
        </ItemPrice>
    )


    const star = (
        <StarRating 
            container={{
                marginLeft : 5, 
            }} 
            size={15} 
            value={4.5} 
            style={{ 
                marginTop: -2, 
                marginLeft : 2, 
                marginRight : 2 
            }}
        />
    )

    const ratings = (

        <BoxTitle>
            { props.ratings.reviews.length > 0 && 
                <p style={{ marginTop : -1, marginLeft : 0, fontWeight: 300, fontSize : 10 }}> 
                    {props.ratings.reviews.length} Ratings
                </p>
            }
        </BoxTitle>
    ) 




    return (
        <div {...css({...props.container, backgroundColor : '', display : 'inline-block', })}>  
            <SpacerV h={6}/>
        
            <X align="left" style={{ height : 'auto', width : '100%' }}>   
                <ProductTitleRsp left style={{ fontSize : 30, color : 'black', display : 'inline-block'}}>
                    {props.title}
                </ProductTitleRsp>
            </X>
            
            
            <div style={{ width : 370, height : 'auto'}}>
                    <Inline float="left">{subtitle}</Inline>
                    <Inline float="left">{star}</Inline>
                    <Inline float="left">{ratings}</Inline>
            </div>    
            
        </div>
    )

}

export default ProductRating;


