import { css } from 'glamor'
import React from 'react'
import { getFontPoppins } from '../../../../assets/fonts/FontPoppins'
import LogoWhiteLime from '../../../../assets/images/logo-white-lime'
import { SectionTitle } from '../../../../client-ui/checkout/CheckoutSectionTitle'
import { SubtitleLarge, TitleLarge } from '../../../../client-ui/checkout/LandingPageLandingSection'
import { CardViewScaled } from '../../../../client-ui/products/ProductOptions'
import PrimaryTitleSubtitle from '../../../../client-ui/shared/PrimaryTitleSubtitle'
import PrimaryText from '../../../../client-ui/shared/PrimaryText'
import SecondaryTitleSubtitle from '../../../../client-ui/shared/SecondaryTitleSubtitle'
import { getConfigDefaults } from '../../../../locust/composables/Layout'
import { SpacerVRsp } from '../../../../locust/composables/SpacerVRsp'
import X from '../../../../locust/composables/X'
import SpacerV from '../../../../locust/sections/SpacerV'
import {  SlapKardLogoSm } from '../../../../locust/components/Auth'

const IntroWhatsIncluded = ( props : {
    title : string, 
    subtitle : string, 
    image : string, 
    cardParams : any,
}) => {

    const cardBackParams = { 
        ...props.cardParams.back, 
        ...props.cardParams.colors,
        logo : { ...props.cardParams.logo },
    }
    

    
    
    return (
        <>

            
            <PrimaryTitleSubtitle mobile={525}>
                <p>What's Included</p>
                <p>{`${props.subtitle}: ${props.title}`}</p>
            </PrimaryTitleSubtitle>

            
            <SpacerVRsp breakpoint={525} before={60} after={30}/>


            <X>
                <CardViewScaled origin="top">
                    { props.cardParams.nodes.getBackNode(cardBackParams)}
                </CardViewScaled>
            </X>

            <SpacerVRsp breakpoint={525} before={80} after={30}/>
        
            <SecondaryTitleSubtitle>
                <>
                   <SlapKardLogoSm/>
                    Slap Kard Business
                </>
                <>Business Smarts.</>

            </SecondaryTitleSubtitle>
            
            <SpacerVRsp breakpoint={525} before={20} after={10}/>
   

            <X>
            <PrimaryText >
                    The world's most viral business card is even more powerful with the all new Social Sharability Suite — and the same NFC and Dynamic QR technology. Dazzle your clients with Tap to Transfer technology and a completely customizable design.  
            </PrimaryText> 
            </X>

            <SpacerV h={30}/>
        
        </>
    )
}





export default IntroWhatsIncluded;

const CobraLogoRsp = () => {
    const on = ( value : number ) => getConfigDefaults().onMedia( value )
    return (
        <div {...css({ 
            width : 45, 
            height : 45, 
            marginTop : -10,
            [on(525)] : {
                width : 30, 
                height : 30,   
            }
         })}>
            <LogoWhiteLime noSubtitle color="black" style={{ marginTop: 10 }}/>
        </div>
    )

}