import { motion } from 'framer-motion'
import { css } from 'glamor'
import React from 'react'
import X from '../../locust/composables/X'
import XY from '../../locust/composables/XY'
import SpacerV from '../../locust/sections/SpacerV'
import { AnimCardCustomization } from '../checkout/AnimCardCustomization'

const ProductViewBox = ( props : {
    img ?: React.ReactNode
    cardValuesFront ?: any 
    cardValuesBack ?: any 
    cardNodes ?: any,
    cardColors?: any,
    cardLogo ?: any,
    cardImages ?: { front : string, back : string, frontThumb : string, backThumb : string },
    scale ?: number, 
}) => {
    const [thumbActivate, setThumbActive] = React.useState(false)
    const [thumbIndex, setThumbIndex] = React.useState(1)
    const cardFront : string = props.cardImages && props.cardImages.frontThumb ? props.cardImages.frontThumb : ""
    const cardBack : string = props.cardImages && props.cardImages.backThumb ? props.cardImages.backThumb : ""


    return (
        <div style={{ marginLeft : 8}}>
        <motion.div                 
            initial={{ 
                display : 'inline-block',
                width : 520,
                transformOrigin: 'top left',
                scale : props.scale ? props.scale : 1.0
            }}
        >  
    


                <div style={{ height : 404 }}>
                    <div style={{ 
                        position : 'absolute', 
                        width : 504, 
                        height : 400, 
                        borderRadius : 8,
                        paddingLeft : 10,
                        //border: '1px solid rgba(0,0,0,0.2)', 
                        overflow : 'hidden',
                        backgroundColor : 'white',
                        
                    }}
                    >
                        <AnimCardCustomization 
                            front={cardFront} 
                            back={cardBack}
                            scale={1.0}
                        />
                    </div>

                    { thumbActivate && 
                        <div style={{ 
                            position : 'absolute', 
                            width : 504, 
                            height : 400, 
                            borderRadius : 8,
                            paddingLeft : 10,
                            //border: '1px solid rgba(0,0,0,0.2)', 
                            overflow : 'hidden',
                            backgroundColor : 'white'
                        }}
                        >
                            { thumbIndex === 1 && 
                                <>
                                    <XY>
                                        {/*
                                        <div style={{ width :  350*1.3, height : (200*1.3)-6, boxShadow: 'rgba(0, 0, 0, .55) 0px 5px 15px' }}>
                                            { props.cardNodes.getFrontNode({...cardFrontParams, w : 350*1.3, h: (200*1.3)-6})}
                                        </div>
                                        */}

                                        
                                        <img
                                            style={{ backgroundColor : '', marginLeft: -10, boxShadow: 'rgba(0, 0, 0, .55) 0px 5px 15px' }}
                                            src={cardFront} 
                                            width={350*0.9} 
                                            height={(200*0.9)-6}
                                        /> 
                                    </XY>
                                </>
                            }

                            { thumbIndex === 2 && 
                                <>
                                    <XY>  
                                        {/*
                                        <div style={{ width :  350*1.3, height : (200*1.3)-6, boxShadow: 'rgba(0, 0, 0, .55) 0px 5px 15px' }}>
                                            { props.cardNodes.getBackNode(cardBackParams)}
                                        </div>
                                        */}
                                        <img
                                        
                                        style={{ backgroundColor : '', marginLeft: -10, boxShadow: 'rgba(0, 0, 0, .55) 0px 5px 15px' }}
                                        src={cardBack} 
                                        width={350*0.9} 
                                        height={(200*0.9)-6}
                                        /> 
                                        
                                    </XY>
                                </>
                            }
                            
                        </div>
                    }
                </div>
                <div style={{ height : 'auto', width : '100%',backgroundColor : '' }}>
                    
                        <SpacerV h={12}/>
                        <X align="left" style={{ height : 90, marginLeft : -5 }}>
                            <ProductViewBoxThumbnail 
                                size={90} 
                                onClick={()=>{setThumbActive(false)}}
                            >
                                <div style={{
                                    padding: 10
                                }}>

                                    <img
                                        style={{ backgroundColor : '', marginTop : -3, marginLeft : 4 }}
                                        src={cardFront} 
                                        width={350*0.17} 
                                        height={200*0.17}
                                    /> 
                                    <img
                                        style={{ backgroundColor : '', marginTop : 2, marginLeft : 4 }}
                                        src={cardBack} 
                                        width={350*0.17} 
                                        height={200*0.17}
                                    /> 
                                    
                                    
                                    
                                </div>
                            </ProductViewBoxThumbnail>

                            <ProductViewBoxThumbnail size={90} onClick={()=>{
                                setThumbActive(true)
                                setThumbIndex(1)
                            }}>
                                <XY>

                                    <SpacerV h={20}/>
                                    <img
                                        style={{ backgroundColor : '', marginTop : -20, marginLeft : 0 }}
                                        src={cardFront} 
                                        width={350*0.2} 
                                        height={200*0.2}
                                    /> 
                                    {/*
                                    <div style={{ width :  250*0.33, height : (280*0.33)-6  }}>
                                        <SpacerV h={20}/>
                                            { props.cardNodes.getFrontNode({...cardFrontParams, w :  250*0.33, h : (280*0.33)-6})}
                                    </div>
                                    */}

                                    
                                </XY>
                            </ProductViewBoxThumbnail>
                            <ProductViewBoxThumbnail size={90} onClick={()=>{
                                setThumbActive(true)
                                setThumbIndex(2)
                            }}>
                                <XY>
                                    <SpacerV h={20}/>
                                    <img
                                        style={{ backgroundColor : '', marginTop : -20, marginLeft : 0 }}
                                        src={cardBack} 
                                        width={350*0.2} 
                                        height={200*0.2}
                                    /> 
                                    {/*
                                    <div style={{ width :  250*0.33, height : (280*0.33)-6  }}>
                                        <SpacerV h={20}/>
                                            { props.cardNodes.getBackNode({...cardBackParams, w :  250*0.33, h : (280*0.33)-6})}
                                    </div>
                                    */}
                                </XY>
                            </ProductViewBoxThumbnail>
                        </X>
                    
                </div>
            
        </motion.div>
        </div>
    )
}

export default ProductViewBox;




export const ProductViewBoxThumbnail = ( props : {
    children ?: any
    size ?: number
    onClick ?: () => void 
}) => {
    return (
                    
                <div
                    onClick={props.onClick} 
                    {...css({ 
                    width : props.size ? props.size : 70, 
                    height : props.size ? props.size : 70, 
                    borderRadius : 4,
                    //paddingLeft : 5,
                    //border: '1px solid rgba(0,0,0,0.2)', 
                    overflow : 'hidden',
                    backgroundColor: "white",
                    marginLeft : 6,
                    marginRight : 6, 
                    cursor : 'pointer',
                    opacity : 0.5,
                    [':hover'] : {
                        opacity : 1
                    }
                })}>
                    
                    {props.children}
                    
                </div>
            
         
        
        
    )
}