import React from 'react'
import X from '../../../../locust/composables/X'
import { SectionFooterIcon } from '../../components/FeatureFooterIcon'
import FeatureProductLR from '../../components/FeatureProductLR'
import { CreditCard2BackFill } from '@styled-icons/bootstrap/CreditCard2BackFill'
import { Premium } from '@styled-icons/fluentui-system-filled/Premium'
import Stack from '../../../../locust/composables/Stack'
import IphoneWhite from '../../../../assets/images/svgs/iphone-white-300x580.svg'
import QRCodeComplex from '../../../../assets/images/svgs/qrcode-ecard-cobra-complex.svg'
import QRCodeSimple from '../../../../assets/images/svgs/qrcode-ecard-cobra-simple.svg'
import { css } from 'glamor'
import SpacerV from '../../../../locust/sections/SpacerV'

const content = {
    title:"Simple Dynamics",
    subtitleLine1:"Simplicity is the",
    subtitleLine2:"greatest sophistication.",
    description:"Slap Kard Simple Dynamics reduces your QR code footprint (and likelihood of scan failure) by storing your data on our servers instead of encoding it directly. This also makes it dynamic. You can information it contains without having to change the code itself!",
    bullets:[
        { bullet : '01', value : 'Less is more. We serve your contact data from our servers instead of in the QR Code directly, making it readable by nearly every device.'},
        { bullet : '02', value : 'Dynamic Reprogramability. You can point your QR code to send people whereever you like. Your favorite Social media, company website, or wherever!'},
        { bullet : '03', value : 'Versital Sharability. You can share your contact details using the Tap to Transfer technology or our the Dynamic QR Scan - its entirely up to you.'}
    ]
}


const DynamicQR = ( props : { right ?: boolean }) => {
    return (
        <>
            <FeatureProductLR
                right={props.right}
                title={content.title}
                subtitleLine1={content.subtitleLine1}
                subtitleLine2={content.subtitleLine2}
                description={content.description}
                bullets={content.bullets}
                image={ <DynamicQRCode/> }
            />

            <SpacerV h={40}/>

            <X>
                <div style={{ width : 120 }}>
                    <SectionFooterIcon value={'Subscription Required'} space={10} img={<CreditCard2BackFill width={25} />}/>
                </div>   
                <div style={{ width : 120 }}>
                    <SectionFooterIcon value={'Premium Feature'} space={10} img={<Premium width={25} />}/>
                </div>   
                
            </X> 
        </>
    )
}
export default DynamicQR;


const DynamicQRCode = () => {

    const blue : string = `linear-gradient(to bottom, rgba(24, 119, 242, 0.3), rgba(23, 169, 253, 0.9))`
    const red : string = `linear-gradient(to bottom, rgba(255, 59, 59, 0.5),  rgba(115, 0, 242, 0.7))`

    return (
        <>
            <div style={{ backgroundColor : '', width: 300, height: 580, marginBottom : 185, overflow : 'hidden', marginTop : 20 }}>
                <Stack>
                    <Phone img={QRCodeComplex}  scan={red}/>
                    <Phone img={QRCodeSimple}   scan ={blue} container={{ marginTop : 260}}/>
                </Stack>
                
                
            </div>
            </>
    )
}

const Phone = ( props : {
    img : string,
    container ?: Record <string,any>
    scan : string
}) => {

    const container = css({
        ...props.container,
    })

    const sizeqr : number = 150
    const yqr : number = 90
    const yfade : number = 230
    return (
        <div {...container}>
            <Stack>
                <>
                    <img 
                        style={{  borderRadius : 8, marginTop : 0 }}
                        width={300} 
                        height={580} 
                        src={IphoneWhite}
                    />
                </>
             
                <>
                    <img 
                        style={{ 
                            marginTop   : yqr, 
                            marginLeft  : 'calc(50% - 150px)',
                        }}
                        width={sizeqr} 
                        height={sizeqr} 
                        src={props.img}
                    />
                   
                </>
                <>
                    <div style={{
                        borderRadius : 0, 
                        borderBottomLeftRadius : 5,
                        borderBottomRightRadius : 5,
                        marginTop   : yqr + (sizeqr/4), 
                        marginLeft  : `calc(50% - ${sizeqr/2}px)`,
                        width:sizeqr,
                        height:sizeqr * (3/4),
                        background : props.scan,
                        borderTop : '4px solid rgba(255, 59, 59, 0.9)'
                    }}></div>
                </>
                
                <FadeToWhite y={yfade}/>
            </Stack>
        </div>

    )
}

const FadeToWhite = ( props : { y ?: number }) => {
    return (
        <div style={{ 
            width : 300, 
            height : 580, 
            marginTop : props.y ? props.y : 0, 
            backgroundColor : ''
        }}>
            <div style={{ 
                width : '100%', 
                height : 50, 
                background : `linear-gradient(to bottom, rgba(255, 255, 255, 0),  rgba(255, 255, 255, 1.0))`,
            }}></div>
            <div style={{ 
                width : '100%', 
                height : 'calc(100% - 50px)', 
                backgroundColor : 'white',
            }}></div>
        </div>

    )
}