import React from 'react'
import { motion } from "framer-motion"
import { css } from 'glamor'
import { getConfigDefaults } from '../composables/Layout'

const HeaderOnScrollWrapper= ( props : { children ?: any, 
    y : number, 
    h ?: number, 
    hideOn ?: number 
    hideB4 ?: number 
}) => {
    const on = ( value : number ) => { return getConfigDefaults().onMedia( value )}

    const h : number = props.h ? props.h : 60
    //const [ scroll, setScroll ] = React.useState(0)
    const [ height, setHeight ] = React.useState(0)
    
    React.useEffect(()=>{
        window.addEventListener('scroll', scrollListener)
        return () => {
            window.removeEventListener('scroll', scrollListener )
        };
    },[])

    const setHeightValue = ( value : number ) => {
        setHeight( value )
    }

    const bindHeight = ( value : number ) => {
        
        if( value >= props.y  ){
            setHeightValue(h)
        }
        if( value < props.y ){
            setHeightValue(0)
        }
            
    }

    const scrollListener = () => {
        const winScroll =
            document.body.scrollTop || 
            document.documentElement.scrollTop
        
        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        
        bindHeight( winScroll )

    }
    

    const params = {
        ...( props.hideB4 ? {
            display : 'none'
        } : {}),
        ...( props.hideB4 ? {
            [on( props.hideB4 )]:{ 
                display : 'block'
            }
        } : {}),
        ...( props.hideOn ? {
            [on( props.hideOn )]:{ 
                display : 'none'
            } 
        } : {})
    }
    return (
        <div {...css(params)}>
            <motion.div
                initial={{ 
                    height : 0,
                    overflow : 'hidden', 
                    position : 'fixed', 
                    top : 0,
                    transformOrigin : 'top' ,
                }}
                animate={ height >= h ? {
                    height : h, 
                    zIndex : 10,
                } : {}}
            >
                {props.children}
            </motion.div>
        </div>
    )
}
export default HeaderOnScrollWrapper;