import React from "react";

const Inline = ( props : {children : any, float ?: 'left' | 'right' }) => {

    const float : any = props.float ? { float : props.float } : {}
    return (
        <div style={{ display : 'inline-block', verticalAlign : 'top', ...float }}>
            { props.children }
        </div>
    )
}
export default Inline;