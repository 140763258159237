import { motion } from 'framer-motion'
import { css } from 'glamor'
import React from 'react'
import { LineDividerSpacer } from '../../locust/components/LineDivider'
import { Popup } from '../../locust/components/PopupSlide'
import { getConfigDefaults } from '../../locust/composables/Layout'
import X from '../../locust/composables/X'
import SpacerV from '../../locust/sections/SpacerV'
import ProductInfo from './ProductInfo'
import ProductOptions from './ProductOptions'
import ProductTitleRsp from './ProductTitleRsp'
import ProductViewBox from './ProductViewBox'


const ProductLanding = ( props : {
    cardParams : any
    optionsBoxScale : number
    setPopup ?: ( props : Popup ) => void
    setShoppingCartLength : ( value : number ) => void   
    setCardParams ?: ( param : any ) => void,
    setProductAdded ?: ( value : boolean ) => void,
    purchaseId ?: string  
})=>{

    const cardParams : any = props.cardParams
    
    const hasCustomizations : boolean = cardParams.info.customization !== undefined && ( 
            cardParams.info.customization.color ||
            cardParams.info.customization.front ||
            cardParams.info.customization.back
    )

    
    const setCardLogo = ( values : any ) => {
        let updatedValues = {
            ...cardParams,
            logo : values
        }
        if( props.setCardParams )
            props.setCardParams(updatedValues)
    }

    const setCardColors = ( values : any ) => {
        let updatedValues = {
            ...cardParams,
            colors : values
        }
        if( props.setCardParams )
            props.setCardParams(updatedValues)
    }

    const setCardValuesFront = ( values : any ) => {
        let updatedValues = {
            ...cardParams,
            front : values
        }
        if( props.setCardParams )
            props.setCardParams(updatedValues)
    }

    const setCardValuesBack = ( values : any ) => {
        let updatedValues = {
            ...cardParams,
            back : values
        }
        if( props.setCardParams )
            props.setCardParams(updatedValues)
    }

    const on = ( value : number ) => getConfigDefaults().onMedia( value )

    return (
        <>
       

                <SpacerV h={40}/>
                    
                <div style={{ 
                    display : 'inline-block',
                    height : 350, 
                    width : 350,
                    overflow : 'hidden',
                    //backgroundColor : 'lime'
                }}>
                    <ProductViewBox 
                        cardValuesFront={cardParams.front}
                        cardValuesBack={cardParams.back}
                        cardNodes={cardParams.nodes}
                        cardColors={cardParams.colors}
                        cardLogo={cardParams.logo}
                        cardImages={cardParams.images}
                        scale={0.65}
                    />
                </div>

                
                    
                <div {...css({  
                    verticalAlign : 'top', 
                    display : 'inline-block',
                    width : '100%',
                    maxWidth : 500,
                    overflow : 'hidden',
                    //border : '1px solid red'  
                })}>
                    
                        
                        
                    
                    <ProductInfo 
                        itemParams={cardParams} 
                        setPopup={props.setPopup}
                        cardValuesFront={cardParams.front}
                        cardValuesBack={cardParams.back}
                        cardLogo={cardParams.logo}
                        cardColors={cardParams.colors}
                        setShoppingCartLength={props.setShoppingCartLength}
                        purchaseId={props.purchaseId}
                        setProductAdded={props.setProductAdded}

                    />
                        
                    <LineDividerSpacer top={20} bottom={20}/>

                    { hasCustomizations && 
                        <>
                            <div {...css({
                                [on(525)] : {
                                    //backgroundColor : 'blue',
                                    width : 'calc(100% - 40px)',
                                    marginLeft : 20,
                                }
                            })}>
                                <X align="left" style={{ height : 'auto', width : '100%' }}>   
                                    <ProductTitleRsp left style={{ color : 'black', display : 'inline-block'}}>
                                        Customize
                                    </ProductTitleRsp>
                                </X>  
                            </div> 
                            <SpacerV h={10}/>
                            
                            <ProductOptions 
                                cardValuesFront={cardParams.front} 
                                setCardValuesFront={setCardValuesFront}
                                cardValuesBack={cardParams.back} 
                                setCardValuesBack={setCardValuesBack}
                                cardColors={cardParams.colors}
                                setCardColors={setCardColors}
                                cardLogo={cardParams.logo}
                                setCardLogo={setCardLogo}
                                setCardParams={props.setCardParams}
                                cardNodes={cardParams.nodes}
                                cardInfo={cardParams.info}
                                scale={1.0}
                            />
                            
                        </>
                        
                    }
                    
                    
                </div>
            
         
        </>
    )
}

export default ProductLanding;

