import { css } from 'glamor'
import React from 'react'
import { getFontMontserrat } from '../../assets/fonts/FontMontserrat'
import { getFontRoboto } from '../../assets/fonts/FontRoboto'
import { getConfigDefaults } from '../../locust/composables/Layout'
import { getChildren } from '../../locust/composables/Shared'
import X from '../../locust/composables/X'

const PrimaryTitleSubtitle = ( props : { children ?: any, mobile?: number, min?: number }) => {

    const children : any[] = getChildren(props.children)
    const config = getConfigDefaults()
    const on = ( value : number ) => { return config.onMedia( value )}
    const mobile : number = props.mobile ? props.mobile : 500
    const min : number = props.min ? props.min : 400

    return (
        <>      
            { children.length > 0 && 
                <PrimaryTitleLarge style={{
                    textAlign : 'center',
                    [on(mobile)] : {
                        marginLeft : 20, 
                        width : 'calc(100% - 40px)',
                        backgroundColor : '',
                        fontSize : 35,
                    },
                    [on(min)] : {
                        fontSize : 28,
                    }

                }}>
                    <X>
                        { children[0] }
                    </X>
                </PrimaryTitleLarge>
            }
            
            { children.length > 1 && 
                <PrimarySubtitleLarge style={{
                    textAlign : 'center',
                    [on(mobile)] : {
                        marginTop : 4, 
                        lineHeight : 1.5,
                        marginLeft : 50, 
                        width : 'calc(100% - 100px)',
                        backgroundColor : '',
                        fontSize : 16,
                    }
                }}>
                    <X>
                          { children[1] }
                    </X>      
                </PrimarySubtitleLarge>
            }


            
        </> 
    )
}

export default PrimaryTitleSubtitle;




export const PrimaryTitleLarge = ( props : { children ?: any, style ?: Record<string,any> }) => {
    return (
        <h1 {...css({
            ...getFontMontserrat(),
            textTransform : 'uppercase',
            color : 'rgba(0,0,0,0.8)',
            fontSize : 45,
            fontWeight  : 900,
            ...props.style
        })}>
            {props.children}
        </h1>
    )
}

export const PrimarySubtitleLarge = ( props : { children ?: any, style ?: Record<string,any> }) => {
    return (
        <h2 {...css({
            ...getFontRoboto(),
            textTransform : 'uppercase',
            color : 'rgba(0,0,0,0.8)',
            fontSize : 20,
            fontWeight  : 300,
            letterSpacing : 2.0,
            ...props.style,
        })}>
            {props.children}
        </h2>
    )
}