import React from 'react';

export const HalfStar = () => (
    <svg
        data-name='Layer 1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 50 50'
    >
        <defs>
            <clipPath id='half-star_svg__a'>
                <path
                    style={{
                        fill: 'none'
                    }}
                    d='M0 0h25v50H0z'
                />
            </clipPath>
        </defs>
        <g
            style={{
                clipPath: 'url(#half-star_svg__a)'
            }}
        >
            <path
                style={{
                    fill: '#f3cc14',
                    fillRule: 'evenodd'
                }}
                d='m50 19.39-17.27-2.51L25 1.22l-7.73 15.66L0 19.39l12.5 12.18-2.95 17.21L25 40.65l15.45 8.13-2.95-17.21L50 19.39z'
                data-name='Compound Shape'
            />
        </g>
    </svg>
);
