import React, { Component, ReactNode } from 'react';
import { css } from 'glamor';
import { FullStar } from '../infographics/FullStar';
import { HalfStar } from '../infographics/FalfStar';

export type StarType =
    | 0
    | 0.5
    | 1.0
    | 1.5
    | 2.0
    | 2.5
    | 3.0
    | 3.5
    | 4.0
    | 4.5
    | 5.0;

export const StarWrapper = ( props : { size ?: number, children ?: any, style ?: Record<string,any>}) => {
    
    const d: number = props.size ? props.size : 25;
    return (
        <div
            {...css({
                width: d,
                height: d,
                marginLeft: 5,
                marginRight: 5,
                display: 'inline-block',
                ...props.style,
            })}
        >
            {props.children}
        </div>
    );
    
}

export default class StarRating extends Component<{
    value: StarType;
    size ?: number;
    style ?: Record<string,any>;
    container?: Record<string,any>;
}> {

    getParams = () => {

        return {
            style : this.props.style,
            size : this.props.size
        }
    }
    getStars = (n: number) => {
        

        if (n === 0) return <></>;
        const nodes: Array<ReactNode> = [];
        let i: number = n;
        while (i-- > 1) {
            nodes.push(<StarWrapper {...this.getParams()}>{FullStar()}</StarWrapper>);
        }
        if (Math.abs(i) === 0.5)
            nodes.push(<StarWrapper {...this.getParams()}>{HalfStar()}</StarWrapper>);
        else nodes.push(<StarWrapper {...this.getParams()}>{FullStar()}</StarWrapper>);
        return <>{nodes}</>;
    };

    render() {
        const total: number = this.props.value;
        return <div {...css({...this.props.container, display : 'inline-block'})}>{this.getStars(total)}</div>;
    }
}
